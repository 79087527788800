<template>
  <div class="app_page_container">
    <van-sticky>
      <van-nav-bar
          title="已报名列表"
          right-text="去报名"
          @click-right="onClickRight"
      />
    </van-sticky>
    <template v-if="listVisible">
      <template v-if="itemList.length > 0">
        <div style="padding: 14px;">
          <div class="item_container" :key="item.id" v-for="(item, index) in itemList">
            <div class="item_left">
              <div>
                <span>报名单位：</span>
                <span class="item_title_1">{{ item.name }}</span>
              </div>

              <div>
                <span>报名时间：</span>
                <span class="item_title_1">{{ item.createTimeStr }}</span>
              </div>

              <div>
                <span>领队姓名：</span>
                <span class="item_title_1">{{ item.leader }}</span>
              </div>

              <div>
                <span>领队手机号：</span>
                <span class="item_title_1">{{ item.leaderPhone }}</span>
              </div>

            </div>
            <div class="item_right">
              <van-button type="primary" @click="detailClick(item, index)" size="small">详情</van-button>
              <div style="height: 14px;"></div>
              <van-button type="danger" @click="removeClick(item, index)" size="small">删除</van-button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <van-empty description="暂无报名信息"/>
      </template>
    </template>
  </div>
</template>

<script>
import {get, post} from '@/api/httpHelp'
import moment from 'moment'
import {Toast} from 'vant'
import {getOpenId} from "@/utils/common";

export default {
  name: "SignUpList",
  data() {
    return {
      itemList: [],
      loading: false,
      listVisible: false,
      url: {
        listSignUp: '/listSignUp',
        removeByUnitId: '/removeByUnitId'
      }
    }
  },
  created() {

  },
  mounted() {
    this.loadData()
  },
  methods: {
    // 去详情
    detailClick(item, index) {
      console.log(index)
      this.$router.push({
        path: "/wx/form",
        query: {
          id: item.id
        }
      })
    },
    // 删除操作
    removeClick(item, index) {
      this.$dialog.confirm({
        message: "确定删除这一项目吗？",
        theme: "round"
      }).then(() => {
        post(this.url.removeByUnitId, {
          unitId: item.id
        }).then(res => {
          console.log(res)
          if (res != null) {
            this.itemList.splice(index, 1);
            Toast.success({
              message: "删除成功",
              forbidClick: true
            })
          }
        }).catch(() => {
        }).finally(() => {
        })
      }).catch(() => {
        console.log("取消")
      })
    },
    // 去报名
    onClickRight() {
      this.$router.push({
        path: '/wx/teamType'
      })
    },
    loadData() {
      let openId = getOpenId()
      if (!openId || openId === '') {
        // TODO 没有openId 该怎么办
        Toast.fail("获取 openId 出错")
      } else {
        if (this.loading) {
          return
        }
        let param = {
          openId: openId
        }
        const loadToast = Toast.loading({
          duration: 0,
          message: '加载中',
          forbidClick: true
        })
        this.loading = true
        get(this.url.listSignUp, param).then(res => {
          if (res == null) {
            this.loading = false
            this.itemList = []
          } else {
            let data = res.result
            if (data && data.length > 0) {
              data.forEach(e => {
                e.createTimeStr = moment(e.createTime).format('YYYY年MM月DD日HH:mm')
              })
              this.itemList = data
            } else {
              this.itemList = []
              this.$router.replace({
                path: '/wx/home'
              })
            }
          }
        }).finally(() => {
          loadToast.clear()
          this.loading = false
          this.listVisible = true
        })
      }
    }
  }
}
</script>

<style scoped>

.item_container {
  background-color: #fff;
  border-radius: 4px;
  padding: 14px;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 14px;
}

.item_left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.item_title_1 {
  color: #333333;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
}

.item_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.van-button {
  padding: 0 14px;
}

</style>